<template>
  <!-- pc -->
  <div class="joinSevnce">
    <div class="banner" :id="'elm' + elmentList[0].id">
      <div class="img-con">
        <img :src="elmentList[0].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[0].phoneImg" v-else />
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec">
        <div class="dec-par" v-animate="'queue-bottom'">
          <p>{{ elmentList[0].descList[1].descName }}</p>
          <p>{{ elmentList[0].descList[2].descName }}</p>
        </div>
      </div>
    </div>
    <!-- 加盟优势 -->
    <div class="join-advantage">
      <p class="advantage-title">{{ elmentList[1].descList[0].descName }}</p>
      <div class="advantage-item">
        <div class="item-div" v-for="index in 6" :key="index" :id="'elm' + elmentList[index + 1].id">
          <img :src="elmentList[index + 1].pcImg" v-if="$store.state.pageset.pcshow" />
          <img :src="elmentList[index + 1].phoneImg" v-else />
          <div class="item-div-dec">
            <p class="p1" v-animate="'queue-bottom'">
              {{ elmentList[index + 1].descList[0].descName }}
            </p>
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content" class="tooltip-div">
                {{ elmentList[index + 1].descList[1].descName }}
              </div>
              <p class="p2" v-animate="'queue-bottom'">
                {{ elmentList[index + 1].descList[1].descName }}
              </p>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="product-advantage">
      <div class="product-right" v-if="!$store.state.pageset.pcshow">
        <img :src="elmentList[13].phoneImg" />
      </div>
      <div class="product-left" :id="'elm' + elmentList[8].id">
        <p class="advantage-title" v-animate="'queue-bottom'">
          {{ elmentList[8].descList[0].descName }}
        </p>
        <p class="advantage-dec" v-animate="'queue-bottom'">
          {{ elmentList[8].descList[1].descName }}
        </p>
        <div class="product-item">
          <!-- 最后一个盒子没有下边框 -->
          <div
            v-for="index in 5"
            :key="index"
            :class="index === 5 ? 'item-div' : 'item-div item-border'"
          >
            <!-- 手机端显示 -->
            <div v-if="!$store.state.pageset.pcshow" class="mobile-item">
              <img :src="elmentList[index + 7].phoneImg" />
              <p class="p1" v-animate="'queue-bottom'">
                {{ elmentList[index + 8].descList[0].descName }}
              </p>
            </div>
            <p class="p2" v-if="!$store.state.pageset.pcshow" v-animate="'queue-bottom'">
              {{ elmentList[index + 8].descList[1].descName }}
            </p>

            <!-- pc端显示 -->
            <img :src="elmentList[index + 7].pcImg" v-if="$store.state.pageset.pcshow" />
            <div class="item-div-dec" v-if="$store.state.pageset.pcshow">
              <p class="p1">{{ elmentList[index + 8].descList[0].descName }}</p>
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content" class="tooltip-div" v-animate="'queue-bottom'">
                  {{ elmentList[index + 8].descList[1].descName }}
                </div>
                <p class="p2" v-animate="'queue-bottom'">
                  {{ elmentList[index + 8].descList[1].descName }}
                </p>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="product-right" v-if="$store.state.pageset.pcshow"  :id="'elm' + elmentList[13].id">
        <img :src="elmentList[13].pcImg" />
      </div>
    </div>

    <!-- 加盟支持 -->
    <div class="join-support">
      <div class="support-left"  :id="'elm' + elmentList[22].id">
        <img :src="elmentList[22].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[22].phoneImg" v-else />
      </div>
      <div class="support-right">
        <p class="support-title">{{ elmentList[14].descList[0].descName }}</p>
        <div class="support-item">
          <!-- 最后一个盒子没有下边框 -->
          <div v-for="index in 8" :key="index" class="item-div" :id="'elm' + elmentList[index + 13].id">
            <img :src="elmentList[index + 13].pcImg" v-if="$store.state.pageset.pcshow" />
            <img :src="elmentList[index + 13].phoneImg" v-else />
            <div class="item-div-dec">
              <p class="p1" v-animate="'queue-bottom'">
                {{ elmentList[index + 14].descList[0].descName }}
              </p>
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content" class="tooltip-div">
                  {{ elmentList[index + 14].descList[1].descName }}
                </div>
                <p class="p2" v-animate="'queue-bottom'">
                  {{ elmentList[index + 14].descList[1].descName }}
                </p>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 海外加盟 -->
    <div class="join-overseas" :id="'elm' + elmentList[23].id">
      <div class="img-con">
        <img :src="elmentList[23].pcImg" v-if="this.$store.state.pageset.pcshow" />
        <img :src="elmentList[23].phoneImg" v-else />
        <div class="title">
          <h1>{{ elmentList[23].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec">
        <div class="dec-par" v-animate="'queue-bottom'">
          <p>{{ elmentList[23].descList[1].descName }}</p>
          <p>{{ elmentList[23].descList[2].descName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import joinSevnce from "./joinSevnce_en.js";

export default joinSevnce;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../joinSevnce.scss";

.tooltip-div {
  width: 500px !important;
}
</style>
