

export default {
    components: {

    },
    data() {
        return {
            // elmentList: [
            //     {
            //         pcElementId:280,
            //         phoneElementId: 303,
            //         descList: [
            //             {
            //                 descName: '合作加盟',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾机器人是一家集特种机器人设计、研发、生产、销售、服务为一体的高新技术企业，凭借多年的技术积累和市场经验，在石油化工巡检机器人领域处于领先地位。',
            //                 sort: 2
            //             }, {
            //                 descName: '为了满足市场需求，拓展业务范围，我们现在诚挚邀请有实力和经验的代理商/经销商加盟，共创辉煌!',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 1
            //     },
            //     {
            //         pcElementId: null,
            //         phoneElementId: null,
            //         descList: [
            //             {
            //                 descName: '加盟优势',
            //                 sort: 1
            //             }],
            //         type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId: 281,
            //         phoneElementId: 304,
            //         descList: [
            //             {
            //                 descName: '强大的品牌支持',
            //                 sort: 1
            //             }, {
            //                 descName: '作为一家行业头部的机器人企业，我们拥有良好的市场口碑和品牌影响力，能够提供强大的品牌支持，快速打开市场。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 3
            //     },
            //     {
            //         pcElementId: 282,
            //         phoneElementId:305,
            //         descList: [
            //             {
            //                 descName: '优质的产品保障',
            //                 sort: 1
            //             }, {
            //                 descName: '我们的产品具有高品质、高性能、高稳定性等特点，满足不同客户的需求。同时，我们还不断进行技术创新和产品升级，确保在市场竞争中始终保持领先地位。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId:283,
            //         phoneElementId:306,
            //         descList: [
            //             {
            //                 descName: '完善的培训体系',
            //                 sort: 1
            //             }, {
            //                 descName: '我们提供全面的产品知识培训、销售技巧培训以及售后服务培训，确保掌握专业的知识和技能，更好地提升市场竞争力。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 5
            //     },
            //     {
            //         pcElementId: 284,
            //         phoneElementId:307,
            //         descList: [
            //             {
            //                 descName: '灵活的合作模式',
            //                 sort: 1
            //             }, {
            //                 descName: '我们提供多种合作模式以供选择，根据区域及渠道资源情况提供合作方案，满足不同合作方向的需求，确保双方利益最大化。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 6
            //     },
            //     {
            //         pcElementId: 285,
            //         phoneElementId: 308,
            //         descList: [
            //             {
            //                 descName: '丰富的市场资源',
            //                 sort: 1
            //             }, {
            //                 descName: '我们拥有广泛的客户资源和行业合作伙伴，提供丰富的市场资源，助力市场业务拓展',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 7
            //     },
            //     {
            //         pcElementId:286,
            //         phoneElementId: 309,
            //         descList: [
            //             {
            //                 descName: '完善的售后服务',
            //                 sort: 1
            //             }, {
            //                 descName: '我们提供全方位的售后服务支持，包括技术支持维修保养、客诉处理等，确保售后服务方面无后顾之。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 8
            //     },
            //     {
            //         pcElementId: 287,
            //         phoneElementId: 310,
            //         descList: [
            //             {
            //                 descName: '产品优势',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾机器人聚焦石油、化工、消防、电气等行业应用，拥有8项核心技术、16项领先功能、。468种算法数量，业务要盖全国近30个重点省市，服务于中石油、中石化、中海油、中化集团等海内外知名企业达到2000余家，为石油、化工、电力、氯碱、消防等行业提供解决方案超4000例。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 9
            //     },
            //     {
            //         pcElementId: 288,
            //         phoneElementId:311,
            //         descList: [
            //             {
            //                 descName: '经济效益',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾机器人能够可以替代每天2~3个班次，每个班次3~5人的联合巡检，一台七腾机器人可以节约每年每人8万-13万元的人力运维费用支出。按照单台机器人10年的寿命计算，总体可节省近约800万-1300万元人力运维费用。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 10
            //     },
            //     {
            //         pcElementId: 289,
            //         phoneElementId:312,
            //         descList: [
            //             {
            //                 descName: '巡检效益',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾机器人能够替代人工进行全天候24小时巡检工作，配备行业先进的算法技术能够精准有效的识别和记录巡检数据，并能够合理安全的存储调用，极大程度提升巡检效率。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 11
            //     }, {
            //         pcElementId: 290,
            //         phoneElementId: 313,
            //         descList: [
            //             {
            //                 descName: '管理效益',
            //                 sort: 1
            //             }, {
            //                 descName: '相较于人工存在不服从、不执行、消极情绪等情况，七腾机器人能够进行合理有效的统一管理管控，有效规范操作流程，加强安全管理，有效提供操作、检修快速反应能力，有效节约设备全寿命周期投入及维护成本。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 12
            //     },
            //     {
            //         pcElementId: 291,
            //         phoneElementId: 314,
            //         descList: [
            //             {
            //                 descName: '安全效益',
            //                 sort: 1
            //             }, {
            //                 descName: '截至目前，在过滤工厂测试数据后，七腾机器人云端平台记录项目预警总数15091起，累计安全生产150万余小时，有效排除安全隐患事故300件，平均减少98%的安全事故发生率，估算免除事故直接经济损失近干亿元。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 13
            //     },
            //     {
            //         pcElementId:292,
            //         phoneElementId:315,
            //         descList: [
            //             {
            //                 descName: '社会效益',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾机器人的应用能够有效保障周围环境安全，同时有效带动相关产业与技术发展、提升企业社会形象。通过机器人替人，实现行业产业数字化转型，助力产业数字化安全化发展。',
            //                 sort: 2
            //             },],
            //         type: 'image',
            //         sort: 14
            //     },
            //     {
            //         pcElementId: 293,
            //         phoneElementId:316,
            //         descList: [
            //             {
            //                 descName: '加盟支持',
            //                 sort: 1
            //             }],
            //         type: 'image',
            //         sort: 15
            //     },
            //     {
            //         pcElementId: 294,
            //         phoneElementId: 317,
            //         descList: [
            //             {
            //                 descName: '产品培训',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '提供全面的产品培训，包括产品知识、技术知识、操作技能、维修保养等方面，确保能够熟练掌握产品特点和优势。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 16
            //     },
            //     {
            //         pcElementId: 295,
            //         phoneElementId:318,
            //         descList: [
            //             {
            //                 descName: '物料支持',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '提供全套的产品手册、案例手册、宣传资料、视频等，以便于更好地了解和推广产品。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 17
            //     },
            //     {
            //         pcElementId:296,
            //         phoneElementId: 319,
            //         descList: [
            //             {
            //                 descName: '解决方案支持',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '提供石油化工行业及拓展行业领域标准化解决方案内容，并由专业的技术人员进行培训讲解。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 18
            //     },
            //     {
            //         pcElementId: 297,
            //         phoneElementId: 320,
            //         descList: [
            //             {
            //                 descName: '市场支持',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '协助制定市场战略，提供市场调查、竞品分析等数据支持，定期进行市场动态、行业动态等信息的分享。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 19
            //     },
            //     {
            //         pcElementId: 298,
            //         phoneElementId: 321,
            //         descList: [
            //             {
            //                 descName: '活动支持',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '每年在全国落地行业展会活动并根据区城市场情况开展推广活动，协助对接更多客户资源，同时扩大品牌影响力。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 20
            //     },
            //     {
            //         pcElementId:299,
            //         phoneElementId: 322,
            //         descList: [
            //             {
            //                 descName: '售后服务支持',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '提供完善的售后服务体系，包括技术支持、维修保养、客户投诉处理等，确保客户问题能够得到及时、有效的解决。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 21
            //     }, {
            //         pcElementId: 300,
            //         phoneElementId: 323,
            //         descList: [
            //             {
            //                 descName: '政策支持',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '根据区域市场发展，依托不同时间节点、产品推新、营销节奏等方向制定相应的营销政策，促进区城市场的稳定和良好发展。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 22
            //     }, {
            //         pcElementId: 301,
            //         phoneElementId: 324,
            //         descList: [
            //             {
            //                 descName: '区域保护支持',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '提供区域独家授权，确保在所辖区城内具有独家经营优势，严格控制跨区域销售，维护市场权益。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 23
            //     },
            //     {
            //         pcElementId: 302,
            //         phoneElementId: 325,
            //         descList: [
            //             {
            //                 descName: '海外加盟',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '七腾机器人一直致力于海外市场的开拓，未来3年公司的业务将向新加坡、马来西亚、沙特、阿联酋等在内的东南亚、中东、欧州等区域市场发展。',
            //                 sort: 2
            //             },
            //             {
            //                 descName: '如有海外地区的合作意向可与我司联系，非常感谢您对于七腾机器人的支持和认可!',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 24
            //     },
            // ],
            elmentList: [],
        }
    },
    created() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
            }, 100);
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        servicePDF() {
            window.open(this.elmentList[16].pcElementId);
        }
    }
}